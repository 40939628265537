import React, { createContext, useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Link, Route, Routes } from 'react-router-dom';
import './App.css';
import './GlobalStyles.scss';
import Header from './Components/js/Header';
import HeaderBanner from './Components/js/HeaderBanner';
import CategoryHomeSlider from './Components/js/categorýHomeSlider';
import ExtraServicesHomeSection from './Components/js/ExtraServicesHomeSection';
import Footer from './Components/js/Footer';
import ProductSectionCategoryCards from './Components/js/ProductSectionCategoryCards';
import ProductsSection from './Components/js/ProductMainSection';


import reorganizeByCategory from './Components/js/ContextFilteringHelpers';
import PopupProductModal from './Components/js/PopupProductModalContent';
import BrowserPopUpContent from './Components/js/BrowserPopupContent';
import HeaderBannerSectionConnectorType from './Components/js/HeaderConnectorType';

//importin database


  //ruta del json



//revertin routin vrg

// Creamos un contexto global
export const ProductsContext = createContext();

function App() {
  const [products, setProducts] = useState([]);
  

  useEffect(() => {
    fetchData();
  
    return () => {
      // Cleanup logic, e.g., cancelling ongoing requests or clearing intervals.
    };
  }, []);
  
 



  const fetchData = async ()=> {
    try {
      
      const json = require("../src/Components/dataBase/dataBase.json")
      const categorizedProductArray = reorganizeByCategory(json.list);
      console.log(categorizedProductArray)

      // una vez tenemos el array, lo podemos pasar al contexto global
      setProducts(categorizedProductArray);
     
    } catch (error) {
      if(error){
        console.log(error)
      }else{
        console.log('todo bien')
      }
    }
  }



/* 
  const fetchData = async () => {
    try {
      const response = await fetch('https://api-mimarte.azurewebsites.net/api/Product/Lista');
      if (!response.ok) {
     
        throw new Error('Error al obtener los datos');
        
      }

      
      const jsonData = await response.json();
      

      // antes de poner el array en el contexto, tenemos que crear el filtro y el orden, ya que vienen enlistados sin orden
      const categorizedProductArray = reorganizeByCategory(jsonData.list);

      // una vez tenemos el array, lo podemos pasar al contexto global
      setProducts(categorizedProductArray);
    } catch (error) {
      
    }
  }; */












  //reemplazamos la llamada a la api con la base de datos local 
/*   const fetchData = ()=>{
    console.log(dataBase + "database -----------------------------")
    const jsonData = dataBase.json();
    //tenemos que crear la lista filtrada
    const categorizedProductArray = reorganizeByCategory(jsonData.list);

    //una vez tenemos la lista, la pasamos al contexto global
    setProducts(categorizedProductArray)
  } */

  // Función para modificar los productos en el contexto
  const updateProducts = (newProducts) => {
    setProducts(newProducts);
  };

  return (
    <ProductsContext.Provider value={{ products, updateProducts }}>
     
     
        <div>
        

          <Routes>
            {/* Asegúrate de importar y definir correctamente el componente HomePage */}
            <Route path="/" element={<HomePage />} />
            <Route path="/products" element={<ProductsSection />} />
          </Routes>
          <BrowserPopUpContent/>
          
        </div>
      
       
      
      
    </ProductsContext.Provider>
  );
}

export default App;



function HomePage() {








  return (
    <div>
      <Header/>
      <HeaderBanner  />
      
      <HeaderBannerSectionConnectorType connectorType="homeConnector"/>
      <CategoryHomeSlider categoryType={1} categoryTitle="Productos Top." />
      <ExtraServicesHomeSection />
      <CategoryHomeSlider categoryType={2} categoryTitle="Nueva Colección." specialClass="home-category-slider__pre-footer-styles" />
      <Footer/>
    </div>
  );
}
